import React, { useState } from 'react';
import './FISPointsDownload.css';
import LoadingSpiner from './util/LoadingSpiner';

function FisPointsDownload() {
    const [distanceURL, setDistanceURL] = useState(null);
    const [sprintURL, setSprintURL] = useState(null);
    const [pointsList, setPointsList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const get_fis_csv = () => {
        setIsLoading(true);
        // var points_list = 1;
        var racers = [];
        var need_csv = true;
        const data_to_send = new FormData();
        data_to_send.append('pointsList', pointsList || 1);
        data_to_send.append('racers', racers);
        data_to_send.append('return_csv', need_csv);
    
        fetch("https://ggpwjy2pju3cttzzmoxhnuidyi0bkuwd.lambda-url.us-west-2.on.aws", {
          method: 'POST',
          body: data_to_send
        })
        .then(response => response.json())
        .then(data => {
          console.log("---data", data);
          // download the csv file 
          const sprint_url = data["sprint"];
          const distance_url = data["distance"];
    
          setDistanceURL(distance_url);
          setSprintURL(sprint_url);
          setIsLoading(false);
          
        }
        )
      }
      
    return(
        <>
        <div id="FIS-points-download">
            <div className='instr'>This gives you a CSV file with points for all racers for the chosen FIS points list.</div>
            <div className='fourm'>
                <input type='text' id='FIS-points' className='points-input' onChange={(event) => setPointsList(event.target.value)}></input>
                <button className='centerd-button'  onClick={get_fis_csv} disabled={isLoading}>
                    {isLoading ? <LoadingSpiner/> : 'Get FIS Lists'}
                </button>
            </div>
            <div className='download-links'>
                {distanceURL && (
                    <a href={distanceURL} download="sprint.csv">
                        Download Distance CSV
                    </a>
                )}
                {sprintURL && (
                    <a href={sprintURL} download="sprint.csv">
                        Download Sprint CSV
                    </a>
                )}
            </div>
        </div>
        </>
    )
}

export default FisPointsDownload;