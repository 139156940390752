/*
    Create XML string here for FIS
    this is a really big function. 
    could have definitely been broken up into smaller functions 
    but it works
*/
export function createXML(headerInfo, data, jurry, setxmlURL) {
    function formatXml(sourceXml) {
        sourceXml = sourceXml.replace(/\t/g, '');
        var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
        var xsltDoc = new DOMParser().parseFromString([
            // describes how we want to modify the XML - indent everything
            '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
            '  <xsl:strip-space elements="*"/>',
            '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
            '    <xsl:value-of select="normalize-space(.)"/>',
            '  </xsl:template>',
            '  <xsl:template match="node()|@*">',
            '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
            '  </xsl:template>',
            '  <xsl:output indent="yes" spaces="8"/>',
            '</xsl:stylesheet>',
        ].join('\n'), 'application/xml');
    
        var xsltProcessor = new XSLTProcessor();    
        xsltProcessor.importStylesheet(xsltDoc);
        var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
        var resultXml = new XMLSerializer().serializeToString(resultDoc);
        return resultXml;
    }
    
    const makeHeaderString = () => {
        const dateNeeded = headerInfo.raceDate.split("-");
        let season = dateNeeded[0]

        // if the season is grater then june (06) then put the season + 1
        if (parseInt(dateNeeded[1]) > 6) {
            season = parseInt(season) + 1
        }

        const xmlString = `\t<Raceheader Sector="CC" Sex="${headerInfo.sex || null}">
    \t\t<Season>${season || ""}</Season>
    \t\t<Codex>${
        headerInfo.codex || ""
        }</Codex>
    \t\t<Nation>${
        headerInfo.nation || ""
        }</Nation>
    \t\t<Discipline>${
        headerInfo.discipline || ""
        }</Discipline>
    \t\t<Category>${
        headerInfo.category || ""
        }</Category>
    \t\t<Racedate>
    \t\t\t<Day>${
        dateNeeded[2] || ""
        }</Day>
    \t\t\t<Month>${
        dateNeeded[1] || ""
        }</Month>
    \t\t\t<Year>${dateNeeded[0] || ""}</Year>
    \t\t</Racedate>
    \t\t<Coordinator>${headerInfo.coordinator || ""}</Coordinator>
    \t\t<HD>${headerInfo.hd || ""}</HD>
    \t\t<MC>${headerInfo.mc || ""}</MC>
    \t\t<TC>${headerInfo.tc || ""}</TC>
    \t\t<Laplength>${headerInfo.lapLength || ""}</Laplength>
    \t\t<Lapnumber>${headerInfo.lapNumber || ""}</Lapnumber>
    \t\t<Name>${headerInfo.eventName || ""}</Name>
    \t\t<CC_format>${headerInfo.format || ""}</CC_format>
    \t\t<Eventname>${headerInfo.eventName || ""}</Eventname>
    \t\t<Place>${headerInfo.place || ""}</Place>
    \t\t<Type>${headerInfo.type || ""}</Type>
    \t\t<Tempunit>${headerInfo.tempunit || ""}</Tempunit>
    \t\t<Longunit>${headerInfo.longunit || ""}</Longunit>
    \t\t<Speedunit>${headerInfo.speedunit || ""}</Speedunit>
    \t\t<Windunit>${headerInfo.windunit || ""}</Windunit>
    \t</Raceheader>`;
        return xmlString;
    };

    const makeRaceInfoString = () => {
        const makeJurryString = (Jurry) => {
        const xmlString = `\t\t<Jury Function="${Jurry["Function"]}">
    \t\t\t\t<Lastname>${Jurry["LastName"]}</Lastname>
    \t\t\t\t<Firstname>${Jurry["FirstName"]}</Firstname>
    \t\t\t\t<nation>${Jurry["Nation"]}</nation>
    \t\t\t</Jury>`;
        return xmlString;
        };

        const jurryStrings = [];
        jurry.forEach((jurry) => {
        const xmlString = makeJurryString(jurry);
        jurryStrings.push(xmlString);
        });

        const xmlString = `\t\t<CC_raceinfo>
        ${jurryStrings.join("\n")}${jurryStrings.length > 0 ? "\n" : ""}
    \t\t\t<Runinfo No="${headerInfo.no || ""}">
    \t\t\t\t<Starttime>${headerInfo.startTime || ""}</Starttime>
    \t\t\t</Runinfo>
    \t\t\t<Usedfislist>${headerInfo.usedFISList || ""}</Usedfislist>
    \t\t\t<Appliedpenalty>${headerInfo.penalty}</Appliedpenalty>
    \t\t\t<Calculatedpenalty>${headerInfo.calculatedPenalty}</Calculatedpenalty>
    \t\t\t<Fvalue>${headerInfo.sugestedFFactor || ""}</Fvalue>
    ${headerInfo.timingBy ? (`\t\t\t<Timingby>${headerInfo.timingBy}`):("")}</Timingby>
    ${headerInfo.dataProcessingBy ? (`\t\t\t<Dataprocessingby>${headerInfo.dataProcessingBy}`):("")}</Dataprocessingby>
    ${headerInfo.softWareCompany ? (`\t\t\t<Softwarecompany>${headerInfo.softWareCompany}`):("")}</Softwarecompany>
    \t\t</CC_raceinfo>`;

        return xmlString;
    };

    // startlists for indidual races
    const makeRacersStringIndStartList = () => {
        // helper function to make the XML for a single racer
        const makeSingleRacerString = (row, index) => {
            let xmlString = `\t\t\t<CC_ranked Status="${row["status"]}">
    \t\t\t\t<Bib>${
            row["Racer #"] || ""
            }</Bib>
    \t\t\t\t<Rank>${
            (index + 1) || ""
            }</Rank>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${
            row["FIScode"] || ""
            }</Fiscode>
    \t\t\t\t\t<Lastname>${
            row["lastname"] || ""
            }</Lastname>
    \t\t\t\t\t<Firstname>${
            row["firstname"] || ""
            }</Firstname>
    \t\t\t\t\t<Sex>${
            row["sex"] || ""
            }</Sex>
    \t\t\t\t\t<Nation>${
            row["Country"] || ""
            }</Nation>
    \t\t\t\t\t<Yearofbirth>${
            row["YOB"] || ""
            }</Yearofbirth>
    \t\t\t\t</Competitor>
    \t\t\t</CC_ranked>`;
            return xmlString;
        };

        // make xml string for each racer
        const xmlStrings = [];
        data.forEach((row, index) => {
            if (row["status"] === "") {
            row["status"] = "QLF";
            }
            // if the racer does not have a first or last name dont do this
            if (
            row["firstname"] !== undefined &&
            row["lastname"] !== undefined &&
            row["status"] === "QLF"
            ) {
            const xmlString = makeSingleRacerString(row, index);
            xmlStrings.push(xmlString);
            }
        });

        // make xml string for all racers
        const xmlString = `\t\t<CC_classified>
        ${xmlStrings.join("\n")}
    \t\t</CC_classified>`;


        return xmlString;
    };

    // startlist for team races
    const makeRacersStringTeamStartList = () => {
        // helper function to make the XML for a single racer
        const makeSingleRacerString = (row, index) => {
            let xmlString = `\t\t\t<CC_ranked Status="${row["status"]}">
    \t\t\t\t<Bib>${
            row["bib"] || ""
            }</Bib>
    \t\t\t\t<Rank>${
            (index + 1) || ""
            }</Rank>
    \t\t\t\t<team>
    \t\t\t\t\t<Fiscode>${
            row["FIScode"] || ""
            }</Fiscode>
    \t\t\t\t\t<teamname>${
            row["teamname"] || ""
            }</teamname>
    \t\t\t\t\t<Sex>${
            row["sex"] || ""
            }</Sex>
    \t\t\t\t\t<Nation>${
            row["nation"] || ""
            }</Nation>
    \t\t\t\t</team>
    \t\t\t</CC_ranked>`;
            return xmlString;
        };

        // make xml string for each racer
        const xmlStrings = [];
        data.forEach((row, index) => {
            if (row["status"] === "") {
            row["status"] = "QLF";
            }
            // if the racer does not have a first or last name dont do this
            if (
            row["teamname"] !== undefined &&
            row["status"] === "QLF"
            ) {
            const xmlString = makeSingleRacerString(row, index);
            xmlStrings.push(xmlString);
            }
        });

        // make xml string for all racers
        const xmlString = `\t\t<CC_classified>
        ${xmlStrings.join("\n")}
    \t\t</CC_classified>`;

        return xmlString;
    };

    // results for individual distance and sprint qualification 
    const makeRacersString = () => {
        // helper function to make the XML for a single racer
        const makeSingleRacerString = (row) => {
        let xmlString = `\t\t\t<CC_ranked Status="${row["status"]}">
    \t\t\t\t<Rank>${
            row["rank"] === undefined || row["rank"] === "" ? "" : row["rank"]
        }</Rank>
    \t\t\t\t<Bib>${
            row["Racer #"] === undefined || row["Racer #"] === ""
            ? ""
            : row["Racer #"]
        }</Bib>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${
            row["FIScode"] === undefined || row["FIScode"] === ""
            ? ""
            : row["FIScode"]
        }</Fiscode>
    \t\t\t\t\t<Lastname>${
            row["lastname"] === undefined || row["lastname"] === ""
            ? ""
            : row["lastname"]
        }</Lastname>
    \t\t\t\t\t<Firstname>${
            row["firstname"] === undefined || row["firstname"] === ""
            ? ""
            : row["firstname"]
        }</Firstname>
    \t\t\t\t\t<Sex>${
            row["sex"] || ''
        }</Sex>
    \t\t\t\t\t<Nation>${
            row["Country"] === undefined || row["Country"] === ""
            ? ""
            : row["Country"]
        }</Nation>
    \t\t\t\t\t<Yearofbirth>${
            row["YOB"] === undefined || row["YOB"] === "" ? "" : row["YOB"]
        }</Yearofbirth>
    \t\t\t\t</Competitor>
    \t\t\t\t<CC_result>
    \t\t\t\t\t<TotalTime>${
            row["time"] === undefined || row["time"] === "" ? "" : row["time"]
        }</TotalTime>
    \t\t\t\t\t<RacePoints>${
            row["Race Points"] === undefined || row["Race Points"] === ""
            ? ""
            : row["Race Points"]
        }</RacePoints>
    \t\t\t\t</CC_result>
    \t\t\t</CC_ranked>`;
        return xmlString;
        };

        const makeSingleRacerStringNotQLF = (row) => {
        let xmlString = `\t\t\t<CC_notranked Status="${row["status"]}">
    \t\t\t\t<Bib>${
            row["Racer #"] || ""
        }</Bib>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${
            row["FIScode"] || ""
        }</Fiscode>
    \t\t\t\t\t<Lastname>${
            row["lastname"] || ""
        }</Lastname>
    \t\t\t\t\t<Firstname>${
            row["firstname"] || ""
        }</Firstname>
    \t\t\t\t\t<Sex>${
            row["sex"] || ""
        }</Sex>
    \t\t\t\t\t<Nation>${
            row["Country"] || ""
        }</Nation>
    \t\t\t\t\t<Yearofbirth>${
            row["YOB"] || ""
        }</Yearofbirth>
    \t\t\t\t</Competitor>
    \t\t\t</CC_notranked>`;
        return xmlString;
        };

        // make xml string for each racer
        const xmlStrings = [];
        data.forEach((row) => {
        if (row["status"] === "") {
            row["status"] = "QLF";
        }
        // if the racer does not have a first or last name dont do this
        if (
            row["firstname"] !== undefined &&
            row["lastname"] !== undefined &&
            row["status"] === "QLF"
        ) {
            const xmlString = makeSingleRacerString(row);
            xmlStrings.push(xmlString);
        }
        });

        // make xml string for all racers
        const xmlString = `\t\t<CC_classified>
        ${xmlStrings.join("\n")}
    \t\t</CC_classified>`;

        // clear the xmlStrings array
        xmlStrings.length = 0;

        data.forEach((row) => {
        if (
            row["firstname"] !== undefined &&
            row["lastname"] !== undefined &&
            row["status"] !== "QLF"
        ) {
            const xmlString = makeSingleRacerStringNotQLF(row);
            xmlStrings.push(xmlString);
        }
        });

        const xmlString2 = `\n\n\t\t<CC_notclassified>
        ${xmlStrings.join("\n")}
    \t\t</CC_notclassified>`;

        return xmlString + "\n" + xmlString2;
    };

    // results for Sprint Final
    const makeRacersStringSprintFinal = () =>{
        // helper function to make the XML for a single racer
        const makeSingleRacerStringTop30 = (row) => {
        let resultDetailString = `\n\t\t\t\t\t<Run Level='quarterfinal'>
        \t\t\t\t<Rank>${row["Quarter Rank"]}</Rank>
        \t\t\t\t<Time>${row["Quarter Time"] || ''}</Time>
        \t\t\t</Run>\n`
        if(row["Highest Level"].toLowerCase() === "final"){
            resultDetailString = `\n\t\t\t\t\t<Run Level='final'>
            \t\t\t\t<Rank>${row["rank"] || ''}</Rank>
            \t\t\t\t<Time>${row["time"] || ''}</Time>
            \t\t\t</Run>\n\t\t\t\t\t<Run Level='semifinal'>
            \t\t\t\t<Rank>${row["Semi Rank"] || ''}</Rank>
            \t\t\t\t<Time>${row["Semi Time"] || ''}</Time>
            \t\t\t</Run>` + resultDetailString
        }else if(row["Highest Level"].toLowerCase() === "semifinal"){
            resultDetailString = `\n\t\t\t\t\t<Run Level='semifinal'>
            \t\t\t\t<Rank>${row["Semi Rank"] || ''}</Rank>
            \t\t\t\t<Time>${row["Semi Time"] || ''}</Time>
            \t\t\t</Run>` + resultDetailString
        }
        
        let xmlString = `\t\t\t<CC_ranked Status="${row["status"]}">
    \t\t\t\t<Rank>${
            row["rank"] || ""
        }</Rank>
    \t\t\t\t<Bib>${
            row["Racer #"] || ""
        }</Bib>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${
            row["FIScode"] || ""
        }</Fiscode>
    \t\t\t\t\t<Lastname>${
            row["lastname"] || ""
        }</Lastname>
    \t\t\t\t\t<Firstname>${
            row["firstname"] || ""
        }</Firstname>
    \t\t\t\t\t<Sex>${
            row["sex"] || ""
        }</Sex>
    \t\t\t\t\t<Nation>${
            row["Country"] || ""
        }</Nation>
    \t\t\t\t\t<Yearofbirth>${
            row["YOB"] || ""
        }</Yearofbirth>
    \t\t\t\t</Competitor>
    \t\t\t\t<CC_result>
    \t\t\t\t\t<Level>${
            row["Highest Level"] || ""
        }</Level>
    \t\t\t\t</CC_result>
    \t\t\t\t<CC_resultdetail>${
            resultDetailString
    }\t\t\t\t</CC_resultdetail>
    \t\t\t</CC_ranked>`;
        return xmlString;
        }

        const makeSingleRacerStringNotQLF = (row) => {      
        let xmlString = `\t\t\t<CC_ranked Status="${row["status"]}">
    \t\t\t\t<Rank>${
            row["rank"] || ""
        }</Rank>
    \t\t\t\t<Bib>${
            row["Racer #"] || ""
        }</Bib>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${
            row["FIScode"] || ""
        }</Fiscode>
    \t\t\t\t\t<Lastname>${
            row["lastname"] || ""
        }</Lastname>
    \t\t\t\t\t<Firstname>${
            row["firstname"] || ""
        }</Firstname>
    \t\t\t\t\t<Sex>${
            row["sex"] || ""
        }</Sex>
    \t\t\t\t\t<Nation>${
            row["Country"] || ""
        }</Nation>
    \t\t\t\t\t<Yearofbirth>${
            row["YOB"] || ""
        }</Yearofbirth>
    \t\t\t\t</Competitor>
    \t\t\t\t<CC_result>
    \t\t\t\t\t<Level>qualification</Level>
    \t\t\t\t</CC_result>
    \t\t\t</CC_ranked>`;
        return xmlString;
        }

        const makeSingleRacerStringDSQ = (row) => {
        let resultDetailString = ''
        if(row["Highest Level"].toLowerCase() === "final"){
            resultDetailString = `<Run Level='final'>
            \t<Time>DSQ</Time>
            </Run>\n<Run Level='semifinal'>
            \t<Rank>${row["Semi Rank"]}</Rank>
            \t<Time>${row["Semi Time"] || ''}</Time>
            </Run>\n<Run Level='quarterfinal'>
            \t<Rank>${row["Quarter Rank"] || ''}</Rank>
            \t<Time>${row["Quarter Time"] || ''}</Time>
            </Run>`
        }else if(row["Highest Level"].toLowerCase() === "semifinal"){
            resultDetailString = `<Run Level='semifinal'>
            \t<Time>DSQ</Time>
            </Run>\n<Run Level='quarterfinal'>
            \t<Rank>${row["Quarter Rank"] || ''}</Rank>
            \t<Time>${row["Quarter Time"] || ''}</Time>
            </Run>`
        }else if(row["Highest Level"].toLowerCase() === "quarterfinal"){
            resultDetailString = `<Run Level='quarterfinal'>
        \t<Time>DSQ</Time>
        </Run>`
        }
        
        let xmlString = `\t\t\t<CC_ranked Status="${row["status"]}">
    \t\t\t\t<Rank>${
            row["rank"] || ""
        }</Rank>
    \t\t\t\t<Bib>${
            row["Racer #"] || ""
        }</Bib>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${
            row["FIScode"] || ""
        }</Fiscode>
    \t\t\t\t\t<Lastname>${
            row["lastname"] || ""
        }</Lastname>
    \t\t\t\t\t<Firstname>${
            row["firstname"] || ""
        }</Firstname>
    \t\t\t\t\t<Sex>${
            row["sex"] || ""
        }</Sex>
    \t\t\t\t\t<Nation>${
            row["Country"] || ""
        }</Nation>
    \t\t\t\t\t<Yearofbirth>${
            row["YOB"] || ""
        }</Yearofbirth>
    \t\t\t\t</Competitor>
    \t\t\t\t<CC_result>
    \t\t\t\t\t<Level>${
            row["Highest Level"] || ""
        }</Level>
    \t\t\t\t</CC_result>
    \t\t\t\t<CC_resultdetail>${
            resultDetailString
    }</CC_resultdetail>
    \t\t\t</CC_ranked>`;
        return xmlString;
        }

        const makeSingleRacerStringDNS = (row) => {
        let xmlString = `\t\t\t<CC_ranked Status="${row["status"]}">
    \t\t\t\t<Rank>${
            row["rank"] || ""
        }</Rank>
    \t\t\t\t<Bib>${
            row["Racer #"] || ""
        }</Bib>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${
            row["FIScode"] || ""
        }</Fiscode>
    \t\t\t\t\t<Lastname>${
            row["lastname"] || ""
        }</Lastname>
    \t\t\t\t\t<Firstname>${
            row["firstname"] || ""
        }</Firstname>
    \t\t\t\t\t<Sex>${
            row["sex"] || ""
        }</Sex>
    \t\t\t\t\t<Nation>${
            row["Country"] || ""
        }</Nation>
    \t\t\t\t\t<Yearofbirth>${
            row["YOB"] || ""
        }</Yearofbirth>
    \t\t\t\t</Competitor>
    \t\t\t\t<CC_result>
    \t\t\t\t\t<Level>${
            "qualification"
        }</Level>
    \t\t\t\t</CC_result>
    \t\t\t</CC_ranked>`;
        return xmlString;
        }

        const makeSingleRacerStringDNF = (row) => {
        
        let xmlString = `\t\t\t<CC_ranked Status="${row["status"]}">
    \t\t\t\t<Rank>${
            row["rank"] || ""
        }</Rank>
    \t\t\t\t<Bib>${
            row["Racer #"] || ""
        }</Bib>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${
            row["FIScode"] || ""
        }</Fiscode>
    \t\t\t\t\t<Lastname>${
            row["lastname"] || ""
        }</Lastname>
    \t\t\t\t\t<Firstname>${
            row["firstname"] || ""
        }</Firstname>
    \t\t\t\t\t<Sex>${
            row["sex"] || ""
        }</Sex>
    \t\t\t\t\t<Nation>${
            row["Country"] || ""
        }</Nation>
    \t\t\t\t\t<Yearofbirth>${
            row["YOB"] || ""
        }</Yearofbirth>
    \t\t\t\t</Competitor>
    \t\t\t\t<CC_result>
    \t\t\t\t\t<Level>${
            row["Highest Level"] || ""
        }</Level>
    \t\t\t\t</CC_result>
    \t\t\t</CC_ranked>`;
        return xmlString;
        }

        // make xml string for each racer
        const xmlStrings = [];
        data.forEach((row) => {
        if (row["status"] === "") {
            row["status"] = "QLF";
        }
        // if the racer does not have a first or last name dont do this
        if (
            row["firstname"] !== undefined &&
            row["lastname"] !== undefined &&
            row["status"] === "QLF"
        ) {
            if(row["Highest Level"]){
            const xmlString = makeSingleRacerStringTop30(row);
            xmlStrings.push(xmlString);
            }else{
            const xmlString = makeSingleRacerStringNotQLF(row);
            xmlStrings.push(xmlString);
            }
        }
        });

        // make xml string for all racers
        const xmlString = `\t\t<CC_classified>
        ${xmlStrings.join("\n")}
    \t\t</CC_classified>`;

        // clear the xmlStrings array
        xmlStrings.length = 0;

        data.forEach((row) => {
        if (
            row["firstname"] !== undefined &&
            row["lastname"] !== undefined &&
            row["status"] !== "QLF"
        ) {
            let xmlString = ''
            if(row["status"] === "DSQ"){
            xmlString = makeSingleRacerStringDSQ(row);
            }else if(row["status"] === "DNS"){
            xmlString = makeSingleRacerStringDNS(row);
            }else if(row["status"] === "DNF"){
            xmlString = makeSingleRacerStringDNF(row);
            }
            xmlStrings.push(xmlString);
        }
        });

        const xmlString2 = `\n\n\t\t<CC_notclassified>
        ${xmlStrings.join("\n")}
    \t\t</CC_notclassified>`;

        return xmlString + "\n" + xmlString2;
    }

    // results for team event
    const makeTeamsString = () => {
        // helper function to make the XML for a single racer
        const makeSingleTeamString = (row) => {
            let xmlString = `\t\t\t<CC_ranked Status="${row["status"]}">
        \t\t\t\t<Rank>${
                row["rank"] || ""
            }</Rank>
        \t\t\t\t<Bib>${
                row["bib"] || ""
            }</Bib>
        \t\t\t\t<Competitor>
        \t\t\t\t\t<Fiscode>${
                row["FIScode"] || ""
            }</Fiscode>
        \t\t\t\t\t<teamname>${
                row["teamname"] || ""
            }</teamname>
        \t\t\t\t\t<Sex>${
                row["sex"] || ""
            }</Sex>
        \t\t\t\t\t<Nation>${
                row["nation"] || ""
            }</Nation>
        \t\t\t\t</Competitor>
        \t\t\t\t<CC_result>
        \t\t\t\t\t<TotalTime>${
                row["time"] || ""
            }</TotalTime>
        \t\t\t\t\t<RacePoints>${
            "1"
            }</RacePoints>
        \t\t\t\t</CC_result>
        \t\t\t</CC_ranked>`;
            return xmlString;
            };
    
        const makeSingleTeamStringNotQLF = (row) => {
        let xmlString = `\t\t\t<CC_notranked Status="${row["status"]}">
    \t\t\t\t<Bib>${
            row["bib"] || ""
        }</Bib>
    \t\t\t\t<Competitor>
    \t\t\t\t\t<Fiscode>${
            row["FIScode"] || ""
        }</Fiscode>
    \t\t\t\t\t<teamname>${
            row["teamname"] || ""
        }</teamname>
    \t\t\t\t\t<Sex>${
        row["sex"] || ""
    }</Sex>
    \t\t\t\t\t<Nation>${
            row["nation"] || ""
        }</Nation>
    \t\t\t\t</Competitor>
    \t\t\t</CC_notranked>`;
        return xmlString;
        };

        // make xml string for each racer
        const xmlStrings = [];
        data.forEach((row) => {
        if (row["status"] === "") {
            row["status"] = "QLF";
        }
        // if the racer does not have a first or last name dont do this
        if (
            row["teamname"] !== undefined &&
            row["status"].toUpperCase() === "QLF"
        ) {
            const xmlString = makeSingleTeamString(row);
            xmlStrings.push(xmlString);
        }
        });

        // make xml string for all racers
        const xmlString = `\t\t<CC_classified>
        ${xmlStrings.join("\n")}
    \t\t</CC_classified>`;

        // clear the xmlStrings array
        xmlStrings.length = 0;

        data.forEach((row) => {
        if (
            row["teamname"] !== undefined &&
            row["status"].toUpperCase() !== "QLF"
        ) {
            const xmlString = makeSingleTeamStringNotQLF(row);
            xmlStrings.push(xmlString);
        }
        });

        const xmlString2 = `\n\n\t\t<CC_notclassified>
        ${xmlStrings.join("\n")}
    \t\t<CC_notclassified>`;

        return xmlString + "\n" + xmlString2;
    }

    // xml string for header
    const headerString = makeHeaderString();
    const raceInfoString = makeRaceInfoString();
    let racersString = ''

    if(headerInfo.type==="Startlist"){
        // this is for STARTLIST
        if(headerInfo.format === "individualDistance" || headerInfo.format === "sprintQualification" || headerInfo.format === "sprintFinal"){
            // DONE (i think)
            racersString = makeRacersStringIndStartList();
        }else if(headerInfo.format === "teamSprint"){
            // DONE (i think)
            racersString = makeRacersStringTeamStartList();
        }else if(headerInfo.format === "team"){
            // DONE (i think)
            racersString = makeRacersStringTeamStartList();
        }else{
            console.warn("headerInfo.format is not valid");
        }
    }else{
        // this is for RESULTS
        if(headerInfo.format === "individualDistance" || headerInfo.format === "sprintQualification"){
        // DONE (i think)
        racersString = makeRacersString();
        }else if(headerInfo.format === "sprintFinal"){
        // TODO: Implement this
        racersString = makeRacersStringSprintFinal();
        }else if(headerInfo.format === "teamSprint"){
            // DONE (i think)
            racersString = makeTeamsString();
        }else if(headerInfo.format === "team"){
            // DONE (i think)
        racersString = makeTeamsString();
        }else{
        console.warn("headerInfo.format is not valid");
        }
    }

    // compile all strings into one
    var xmlString =
        `<?xml version="1.0" encoding="UTF-8"?>\n<!-- Created by Zone4 Systems www.zone4.ca -->\n<Fisresults>\n` +
        headerString +
        "\n" +
        "\t<CC_race>\n" +
        raceInfoString +
        "\n" +
        racersString +
        "\n" +
        "\t</CC_race>\n" +
        "</Fisresults>";

    xmlString = formatXml(xmlString);
    const xmlBlob = new Blob([xmlString], { type: "text/xml" });
    const xmlUrl = URL.createObjectURL(xmlBlob);

    setxmlURL(xmlUrl);
};