import React, { useEffect, useState } from "react";
import "./css/DataCleanUp.css";
import LoadingSpinner from './LoadingSpiner';
import CalculatePoints from "./CalculatePoints";
import NewCSVPopup from "./NewCSVPopup";

function FISDataCleanUp(props) {
    const csvData = props.csvData;
    const setCsvData = props.setCsvData;
    const headerInfo = props.headerInfo;
    const setHeaderInfo = props.setHeaderInfo;


    // STATES
    const [isLoading, setIsLoading] = useState(false);
    // popup for showing the changes that will be made to the csv data from the fis DB
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const  originalData = headerInfo.originalData;

    // FUNCTIONS
    const handlePopupClose = () => {
        setIsPopupOpen(false);
        setPopupData(null);
    };

    const checkForNonFISLicenceRacers = () =>{
        let unLicencedRacers = []
        // go through the csvData and make sure every racer has a FIScode.
        csvData.forEach((row) => {
            if(!row["FIScode"]){
                unLicencedRacers.push(row)
            }
        })

        // remove the racers from the results
        let updatedCsvData = csvData.filter(racer => racer["FIScode"])
        // now update the ranking for the updatedCSVData based on the time. if theyhvae the same time then give them the same rank
        let updatedRank = 1
        let lastTime = 0
        let skipRankFromTie = 0
        updatedCsvData.forEach((racer, idx) => {
            if(racer['status'] === '' || racer['status'] === 'QLF'){
                if(racer["time"] !== lastTime){
                    racer["rank"] = updatedRank + skipRankFromTie
                    // skipRankFromTie = 0
                    updatedRank += 1
                }
                else{
                    racer["rank"] = updatedRank - 1 + skipRankFromTie
                    skipRankFromTie += 1
                }
                lastTime = racer["time"]
            }
        })

        return updatedCsvData
    }

    const checkForMissingRacers = async (data, licenceList) => {
        const parseXMLString = (xmlString) => {
            let result = {};

            // Splitting XML string based on tags
            const tags = xmlString.split('<');

            for(let i = 0; i < tags.length; i ++){
                if(tags[i].includes('sectorcode="CC"')){
                    for( let j = i; j < tags.length; j++){
                        //  if its not a closing tag
                        if(!tags[j].split('>')[1]?.includes('\n')){
                            if(tags[j].includes('fiscode>')){
                                result['fiscode'] = tags[j].split('>')[1]
                            }
                            if(tags[j].includes('lastname>')){
                                result['lastname'] = tags[j].split('>')[1]
                            }
                            if(tags[j].includes('firstname>')){
                                result['firstname'] = tags[j].split('>')[1]
                            }
                            if(tags[j].includes('gender>')){
                                result['gender'] = tags[j].split('>')[1]
                            }
                            if(tags[j].includes('nationcode>')){
                                result['nationcode'] = tags[j].split('>')[1]
                            }
                            if(tags[j].includes('year>')){
                                result['year'] = tags[j].split('>')[1]
                            }
                            if(result['fiscode'] && result['lastname'] && result['firstname'] && result['gender'] && result['nationcode'] && result['year']){
                                break
                            }
                        }
                    }
                } 
            }

            return result || null
        }

        // check the new data for racers that got nouthing trutned for them. in that case then hit the api for them and add thei names, country and yob
        let knownRacers = [];
        (data || []).forEach((racer) => {
            knownRacers.push(racer['fis_licence'])
        })

        const notKnownRacers = licenceList.filter(racer => !knownRacers.includes(racer))

        // for parsing the xml

        console.log('not know people:', notKnownRacers)

        for(let i = 0; i < notKnownRacers.length; i++){
            await new Promise(resolve => setTimeout(resolve, 1000));

            console.log('hitting api for racer:', notKnownRacers[i])
            // hit the fis api for each fis list untill it returns one
            let apiUrl = `https://www.fis-ski.com/DB/general/search.html?search=${notKnownRacers[i]}&feed=false&byfiscode=true`

            try {
                // Send a GET request to the API endpoint using fetch
                const response = await fetch(apiUrl);
                if (!response.ok) {
                  throw new Error(`HTTP error! Status: ${response.status}`);
                }
            
                // Parse the XML response
                const xmlData = await response.text()
                console.log('parsing xml for racer:', notKnownRacers[i])
                let parsedData = await parseXMLString(xmlData)
                console.log('xml parse result:', parsedData)

                if(parsedData){
                    // this is the person to add
                    let personInfo = {}
                    personInfo['first_name'] = parsedData['firstname']
                    personInfo['last_name'] = parsedData['lastname']
                    personInfo['gender'] = parsedData['gender']
                    personInfo['nation'] = parsedData['nationcode']
                    personInfo['fis_licence'] = parsedData['fiscode']
                    personInfo['date_of_birth'] = parsedData['year']
                    personInfo['fis_points'] = {'DI': null, 'SP': null}
                    data.push(personInfo)
                }
            
              } catch (error) {
                console.error(`Error fetching data for racer ${notKnownRacers[i]}:`, error.message);
              }
        }
        return data
    }

    const getFisData = () => {
        setIsLoading(true);

        let newCSVData = checkForNonFISLicenceRacers()

        var racers = [];
        (newCSVData || csvData).forEach((row) => {
            if(row["FIScode"]){
                racers.push(row["FIScode"]);
            }
        });

        var points_list = parseInt(headerInfo['usedFISList']);

        const data_to_send = new FormData();
        data_to_send.append('points_list', points_list);
        data_to_send.append('racers', racers);
        data_to_send.append('return_csv', false);

        fetch("https://ggpwjy2pju3cttzzmoxhnuidyi0bkuwd.lambda-url.us-west-2.on.aws", {
        method: 'POST',
        body: data_to_send
        })
        .then(response => response.json())
        .then(data => {
            if(data["message"] === undefined){
                checkForMissingRacers(data, racers)
                .then((result)=>{
                    newCSVData = changeCSVData(result, newCSVData);
                    setIsLoading(false);
                    setPopupData(newCSVData);
                    setIsPopupOpen(true);
                })
                .catch((err)=>{
                    console.warn('Error:', err)
                })
            }
            else{
                window.alert(data["message"]);
                setIsLoading(false);
            }
        })
    }

    const changeCSVData = (data, newCSVData) => {
        const checkNames = (apiRow, row) => {
            // if they are the same then set the first name and last name to the values from the api
            if(row["firstname"] !== apiRow["first_name"]){
                row["firstname"] = apiRow["first_name"]
            };

            if(row["lastname"] !== apiRow["last_name"]){
                row["lastname"] = apiRow["last_name"]
            };
        }

        const checkFISPoints = (apiRow, row) => {
            // if they are the same then set the FISpoints to the values from the api
            if(row["FISpoints"] !== apiRow["fis_points"][headerInfo["discipline"]]){
                row["FISpoints"] = apiRow["fis_points"][headerInfo["discipline"]]
            };
        }

        const addFISPoints = (apiRow, row) => {
            // if they are the same then set the FISpoints to the values from the api
            let points = null
            try{
                points = parseFloat(apiRow["fis_points"][headerInfo["discipline"]])
            } catch(err) {
                points = null
            }
            row["FISpoints"] = points;
            return 1;
        }

        const checkNationality = (apiRow, row) => {
            if(row["Country"] !== apiRow['nation']){
                row["Country"] = apiRow["nation"]
                return 1;
            }
            return 0;
        }

        const checkYOB = (apiRow, row) => {
            if(row["YOB"] !== apiRow['date_of_birth'].split("-")[0]){
                row["YOB"] = apiRow['date_of_birth'].split("-")[0];
                return 1;
            }
            return 0;
        }

        const addGender = (apiRow, row) => {
            row['sex'] = apiRow['gender'];
        }

        var add_nation = 0;
        var add_YOB = 0;
        var add_fis_points = 0;

        newCSVData.forEach((row) => {
            if(row["FIScode"]){
                data.forEach((apiRow) => {
                    // check if the first name and last name are the same
                    if(row["FIScode"] === apiRow["fis_licence"]){
                        // check the names
                        checkNames(apiRow, row);
                        // add gender from fis data
                        addGender(apiRow, row);

                        // if there are FISpoints in the row then check them. else add them
                        if(row["FISpoints"]){
                            checkFISPoints(apiRow, row);
                        } else{
                            add_fis_points += addFISPoints(apiRow, row);
                        }
                        // check nationality of racer
                        add_nation += checkNationality(apiRow, row);
                        
                        // check YOB of racer
                        add_YOB += checkYOB(apiRow, row);
                    }
                });
            }
        });
        let headers = headerInfo.headers;
        if(add_nation > 0 && headers.includes('Country') === false){
            headers.push('Country');
            setHeaderInfo((prevState) => ({
                ...prevState,
                "headers":headers
            }));
        }
        if(add_YOB > 0 && headers.includes('YOB') === false){
            headers.push('YOB');
            setHeaderInfo((prevState) => ({
                ...prevState,
                "headers":headers
            }));
        }
        if(add_fis_points > 0 && headers.includes('FISpoints') === false){
            headers.push('FISpoints');
            setHeaderInfo((prevState) => ({
                ...prevState,
                "headers":headers
            }));
        }

        // setCsvData(newCSVData);
        return newCSVData;
    }

    // calculate the top 5 racers with the lowest FISpoints. this is hard coded and needs to change depending on the fild of the csv.
    const calculateTop5Racers = () => {
    const sortedPeople = [...csvData].sort(
      (a, b) => a["FISpoints"] - b["FISpoints"]
    );
    
    const filteredRacers = sortedPeople.filter(
      racer => racer.rank >= 1 && racer.rank <= 5
    );
    
    const sortedFilteredRacers = [...filteredRacers].sort(
      (a, b) => a.rank - b.rank
    );
    // set the header info wutht he top 5 racers
    setHeaderInfo((prevState) => ({
        ...prevState,
        "top5Racers":sortedFilteredRacers
        }));
    } 

    // make the popup visable or not
    const ToggleCalculatePenalty = () => {
    // create popup for calculating penalty
    const overlay = document.getElementById('overlay');
    if (overlay.style.display === 'flex') {
      overlay.style.display = 'none';
    }
    else {
      overlay.style.display = 'flex';
      calculateTop5Racers();
    }

    }

    useEffect(() => {
        console.log('original data changed:', originalData)
    }, [originalData])


  return (
    <>
      <div id="dataCleanUp">
        <div id="dataCleanUpBox">
          <div id="instr">
            Once you have imput all the corect info for the Header Section. you
            can then calculate the FIS points for the race if they do not have
            any. First you can check the data agenst the FIS database. To do so
            use the button below. then you can calculate the FIS points.
          </div>
          <button id="checkData" onClick={getFisData} disabled={isLoading || !csvData || !(csvData.length > 0)}>
            {isLoading ? <LoadingSpinner /> : "Check Data against FIS Data"}
          </button>
          <button id="calcAndTog" onClick={ToggleCalculatePenalty} disabled={!csvData || !(csvData.length > 0) || headerInfo.format === 'sprintFinal'}>
            Calculate Penalty / Race Points
          </button>
        </div>

        <div id="calculate-points">
            <CalculatePoints headerInfo={headerInfo} setHeaderInfo={setHeaderInfo} csvData={csvData} setCsvData={setCsvData} ToggleCalculatePenalty={ToggleCalculatePenalty}/>
        </div>
      </div>

      <NewCSVPopup
        isPopupOpen={isPopupOpen}
        closeWindow={handlePopupClose}
        newData={popupData}
        oldData={originalData}
        headerInfo={headerInfo}
        headers={headerInfo.headers}
        setCsvData={setCsvData}
        setHeaderInfo={setHeaderInfo}
      />

    </>
  );
}

export default FISDataCleanUp;