import React, { useEffect, useState } from "react";
import NavBar from "./NavBar";
import ReactMarkdown from 'react-markdown';
import documentationFile from './documentation.md';
import Zone4Header from './util/Zone4Header'
import packageJson from '../package.json';
import './Documentation.css';
import './MainFourm.css';

function Documentation() {
    const [markdownContent, setMarkdownContent] = useState("");
    const APP_VERSION = packageJson.version

    useEffect(() => {
        fetch(documentationFile)
            .then((res) => res.text())
            .then((text) => setMarkdownContent(text))
            .catch((error) => console.error("Error fetching markdown file:", error));
    }, []);

    return (
        <div className="Centerd-body">
            <div className="fixed-width-box">
                <div className="app-version"></div>
                <Zone4Header version={APP_VERSION}/>
                <NavBar current='Documentation' />
                <div className="documentation-continer">
                    <ReactMarkdown>{markdownContent}</ReactMarkdown>
                </div>
            </div>
            <div className="rendering-test">rendering test</div>
        </div>
    );
}

export default Documentation;
