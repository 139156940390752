import Papa from 'papaparse';
import './css/FileInput.css'

function FileInput(props){
  const setFile = props.setFile

  const downloadIndividualCSVTemplate = () => {

    const makeTemplateCSV = () => {
      const headers = ['rank', 'time', 'status', 'firstname', 'lastname', 'Racer #', 'FIScode', 'USSAcode', 'FISpoints', 'NRLpoints', 'YOB', 'Country', 'Race Points'];
      const csv = Papa.unparse({
        fields: headers,
      });
    
      return csv;
    };


    const csvData = makeTemplateCSV();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'Individual_Template.csv');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const downloadSprintFinalCSVTemplate = () => {
    const makeTemplateCSV = () => {
      const headers = [
          'rank', 
          'time',
          'status', 
          'firstname', 
          'lastname', 
          'Racer #', 
          'FIScode',
          'USSAcode', 
          'FISpoints', 
          'NRLpoints', 
          'YOB', 
          'Country', 
          'Race Points', 
          'Highest Level'
      ];
      // const rows = Array(6).fill({ 'Highest Level': 'final' });
      const rows = Array.from({ length: 30 }, (_, index) => {
        if (index >= 0 && index <= 5) {
          return { 'Highest Level': 'final' };
        } else if (index >= 6 && index <= 11) {
          return { 'Highest Level': 'semifinal' };
        } else if (index >= 12 && index <= 29) {
          return { 'Highest Level': 'quarterfinal' };
        }
        return {}; // default empty object
      });
      const csv = Papa.unparse({
        fields: headers,
        data: rows
      });
    
      return csv;
    };


    const csvData = makeTemplateCSV();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'Sprint_Final_Template.csv');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
  }
  
  const downloadTeamCSVTemplate = () => {
    const makeTemplateCSV = () => {
      const headers = ['rank', 'bib', 'time', 'status', 'teamname', 'nation', 'sex',  'FIScode'];
      const csv = Papa.unparse({
        fields: headers,
      });
    
      return csv;
    };


    const csvData = makeTemplateCSV();
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'Team_Template.csv');

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  
  }

  return(
    <>
      <form id="csvFileUpLoadBox" onSubmit={(e)=> e.preventDefault()}>
        <input 
          id="csvFileInput" 
          type="file" 
          accept=".csv" 
          onChange={(e) => {
            const file = e.target.files[0];
            setFile(file);
            // e.target.value = '';
          }}
        ></input>
        <div id="instr">Please input a CSV file with the folowing headers. "rank", "time", "status",	"firstname",	"lastname",	"Racer #", "FIS Licence",	"FIS (DI or SP) Points",	"YOB",	"Country",	"Race Points". Or download the example CSV below and replace the category name with your category name and then fill in the data in the corect coloums leaving the row where the category name is blank.</div>
        <div className='button-col'>
          <div className='download-button' onClick={downloadIndividualCSVTemplate}>Download Example CSV Format(Individual Race / Start List)</div>
          <div className='download-button' onClick={downloadSprintFinalCSVTemplate}>Download Example CSV Format (Sprint Final)</div>
          <div className='download-button' onClick={downloadTeamCSVTemplate}>Download Example CSV Format (Team Event)</div>
        </div>
      </form>
    </>
  )
}

export default FileInput;