import React, { useEffect, useState } from "react";
import "./css/HeaderInfoFourm.css";

function useLocalStorage(key, initialValue) {
  const savedValue = localStorage.getItem(key)
    ? JSON.parse(localStorage.getItem(key))
    : initialValue;

  const [value, setValue] = useState(savedValue);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

function HeaderInfoFourm(props) {
  const setHeaderInfo = props.setHeaderInfo;
  const headerInfo = props.headerInfo;
  const parentElement = props.parent
  const STATES = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']
  const CATEGORYS = {
  	'FIS Race' : 'FIS',
  	'US SuperTour' : 'UST',
  	'Continental Cup' : 'COC',
  	// 'Sprint Qualification' : 'SPWQ',
  	'National Championships' : 'NC',
  	'National Junior Championships' : 'NJC',
  	'Asia Winter Games' : 'AWG',
  	'Australia/New Zealand Cup' : 'ANC',
  	'Balkan Cup' : 'BC',
  	'Citizen' : 'CIT',
  	'Eastern Europe Cup' : 'EEC',
  	'European Youth Olympic Festival' : 'EYOF',
  	'Far East Cup' : 'FEC',
  	'International Military Sports Council' : 'CISM',
  	'Juniors' : 'JUN',
  	'Marathon Cup' : 'MC',
  	'Nor-Am Cup' : 'NAC',
  	'Olympic Winter Games' : 'OWG',
  	'Organisation of Alps Nations' : 'OPA',
  	'Popular Cross-Country Races' : 'ML',
  	'Roller Ski Junior' : 'ROLJ',
  	'Roller Ski Junior Sprint World Cup' : 'ROLJSWC',
  	'Roller Ski Junior Sprint World Cup Qualifying' : 'ROLJSPWQ',
  	'Roller Ski Junior World Cup' : 'ROLJWC',
  	'Roller Ski Sprint World Cup' : 'ROLSWC',
  	'Roller Ski Sprint World Cup Qualifying' : 'ROLSPWQ',
  	'Roller Ski World Cup' : 'ROLWC',
  	'Roller Ski World Junior Championships' : 'ROLWJC',
  	'Roller Ski World Senior Championships' : 'ROLWSC',
  	'Roller Skiing' : 'ROL',
  	'Scandinavian Cup' : 'SCAN',
  	'Slavic Cup' : 'SC',
  	'South America Cup' : 'SAC',
  	'Sprint World Junior Qualifying' : 'SPJQ',
  	'Sprint World U23 Qualifying' : 'SPUQ',
  	'Sprint Youth Olympics Qualifying' : 'SPYQ',
  	'Stage World Cup' : 'SWC',
  	'Under 23' : 'U23',
  	'Universiade' : 'UVS',
  	'University Race' : 'UNI',
  	'World Cup' : 'WC',
  	'World Junior Championships' : 'WJC',
  	'World Loppet' : 'WL',
  	'World Loppet Cup' : 'WLC',
  	'World Ski Championships' : 'WSC',
  	'Youth Olympic Games' : 'YOG',
    'National Ranking List Race': 'NRL'
  }

  const [isGenralSettingsVisable, setIsGenralSettingsVisable] = useLocalStorage('isGenralSettingsVisable', true)
  const [isEventInfoVisable, setIsEventInfoVisable] = useLocalStorage('isEventInfoVisable', true)
  const [isRaceInfoVisable, setIsRaceInfoVisable] = useLocalStorage('isRaceInfoVisable', true)
  const [isCourseInfoVisable, setIsCourseInfoVisable] = useLocalStorage('isCourseInfoVisable', true)
  const [isFileInfoVisable, setIsFileInfoVisable] = useLocalStorage('isFileInfoVisable', true)

  const nations = [
    "AUS","AUT","BEL","BUL","CAN","CHI","CHN","CRO","CZE","DEN","ESP","EST","FIN","FRA","GBR","GER","GRE","HUN","IRL","ISL","ISR","ITA","JPN","KAZ","KOR","LAT","LIE","LTU","LUX","MEX","MGL","MON","NED","NOR","NZL","POL","POR","ROU","RSA","RUS","SLO","SUI","SVK","SWE","TUR","UKR","USA","UZB",
  ];
  
      // handle input change for the header info
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    // if the input is a date, split it into an array
    if (id === "Racedate") {
      const dateArray = value.split("-");
      // set the date, month, and year to the values in the input field 
      setHeaderInfo((prevState) => ({
        ...prevState,
        "Racedate": dateArray
      }));
    }
    else{
      setHeaderInfo((prevState) => ({
        ...prevState,
        [id]: value
      }));
    }
  };

  // these handle toe colapsing of the sub tables
  const collapseGentalSettings = () =>{
    console.log("collapse")
    setIsGenralSettingsVisable(!isGenralSettingsVisable)
  }

  const collapseEventInfo = () => {
    setIsEventInfoVisable(!isEventInfoVisable)
  }

  const collapseRaceInfo = () => {
    setIsRaceInfoVisable(!isRaceInfoVisable)
  }

  const collapseCourseInfo = () =>{
    setIsCourseInfoVisable(!isCourseInfoVisable)
  }

  const collapseFileInfo = () => {
    setIsFileInfoVisable(!isFileInfoVisable)
  }

  useEffect(() => {
    const modifiedHeaderInfo = { ...headerInfo };
    delete modifiedHeaderInfo.originalData;

    // depending on it this is a child of ussa or fis fourms it saves the header info in a difrent place
    let storageName = parentElement === "USSAfourm" ? ('USSAHeaderInfo') : ('FISHeaderInfo')
  
    // Save the modified header info to local storage
    localStorage.setItem(storageName, JSON.stringify(modifiedHeaderInfo));
  }, [headerInfo]);
  
  return (
      <>
      {/* Header info input box */}
      <div id="HeaderInfo">
          <div id="headerInfoBox">
            <form onSubmit={(e) => e.preventDefault()}>
                {/* Genral Settings */}
                <div className="sub-title" id="general-settings" onClick={collapseGentalSettings}>
                  <div className="sub-title-div">General Settings</div>
                  {isGenralSettingsVisable ? <div className='triangle-down'></div> : <div className='triangle-down rotate-90'></div>}
                </div>
                {isGenralSettingsVisable && <table 
                  className='sub-table' 
                  id="general-settings-table"
                >
                  <tbody>
                    {/* select Tempunit */}
                    <tr>
                      <td>Select a Temp Unit: </td>
                      <td>
                          <select
                          id="tempunit"
                          defaultValue={headerInfo.tempunit || null}
                          onChange={handleInputChange}
                          >
                          <option value="C">Celsius</option>
                          <option value="F">Fahrenheit</option>
                          <option value="K">Kelvin</option>
                          </select>
                      </td>
                    </tr>
                    {/* select Longunit */}
                    <tr>
                      <td>Select a Long Unit: </td>
                      <td>
                          <select
                          id="longunit"
                          defaultValue={headerInfo.longunit || null}
                          onChange={handleInputChange}
                          >
                          <option value="m">Meters</option>
                          <option value="km">Kilometers</option>
                          <option value="mi">Miles</option>
                          <option value="in">Inches</option>
                          <option value="y">Yards</option>
                          </select>
                      </td>
                    </tr>
                    {/* select Speedunit */}
                    <tr>
                      <td>Select a Speed Unit: </td>
                      <td>
                          <select
                          id="speedunit"
                          defaultValue={headerInfo.speedunit || null}
                          onChange={handleInputChange}
                          >
                          <option value="kmh">Kilometers/Hour</option>
                          <option value="mph">Miles/Hour</option>
                          <option value="ms">Meters/Second</option>
                          </select>
                      </td>
                    </tr>
                    {/* select Windunit */}
                    <tr>
                      <td>Select a Wind Unit: </td>
                      <td>
                          <select
                          id="windunit"
                          defaultValue={headerInfo.windunit || null}
                          onChange={handleInputChange}
                          >
                          <option value="ms">Meters/Second</option>
                          <option value="kmh">Kilometers/Hour</option>
                          <option value="mph">Miles/Hour</option>
                          <option value="kn">Knots</option>
                          <option value="bft">Beaufort</option>
                          </select>
                      </td>
                    </tr>
                    {/* input Timingby */}
                    <tr>
                      <td>Timing Company: </td>
                      <td>
                        <input
                          id="timingBy"
                          type="text"
                          defaultValue={headerInfo.timingBy || null}
                          onChange={handleInputChange}
                          >
                        </input>
                      </td>
                    </tr>
                    {/* input Softwear Company */}
                    <tr>
                      <td>Softwear Company: </td>
                      <td>
                        <input
                          id="softWareCompany"
                          type="text"
                          defaultValue={headerInfo.softWareCompany || null}
                          onChange={handleInputChange}
                          >
                        </input>
                      </td>
                    </tr>
                    {/* input Data Processing By */}
                    <tr>
                      <td>Data Processing By: </td>
                      <td>
                        <input
                          id="dataProcessingBy"
                          type="text"
                          defaultValue={headerInfo.dataProcessingBy || null}
                          onChange={handleInputChange}
                          >
                        </input>
                      </td>
                    </tr>
                  </tbody>
                </table>}

                {/* Event Info */}
                <div className="sub-title" id="event-info" onClick={collapseEventInfo}>
                  <div className="sub-title-div">Event Info</div>
                  {isEventInfoVisable ? <div className='triangle-down'></div> : <div className='triangle-down rotate-90'></div>}
                </div>
                {isEventInfoVisable && <table 
                  className='sub-table' 
                  id='event-info-table'
                >
                  <tbody>
                    {/* event name input */}
                    <tr>
                      <td>Event Name: </td>
                      <td>
                          <input
                          type="text"
                          id="eventName"
                          onChange={handleInputChange}
                          defaultValue={headerInfo.eventName || null}
                          ></input>
                      </td>
                    </tr>

                    {/* club name input */}
                    {parentElement === "USSAfourm" ? (
                      <tr>
                        <td>Club Name: </td>
                        <td>
                            <input
                            type="text"
                            id="clubName"
                            onChange={handleInputChange}
                            defaultValue={headerInfo.clubName || null}
                            ></input>
                        </td>
                      </tr>
                    ) : ('')}

                    {/* place input */}
                    <tr>
                      <td>Place: </td>
                      <td>
                          <input
                          type="text"
                          id="place"
                          onChange={handleInputChange}
                          defaultValue={headerInfo.place || null}
                          ></input>
                      </td>
                    </tr>

                    {/* select nation */}
                    <tr>
                      <td>Select a Nation: </td>
                      <td>
                          <select
                          id="nation"
                          defaultValue={headerInfo.nation || "CAN"}
                          onChange={handleInputChange}
                          >
                          {nations.map((nation) => (
                              <option key={nation} value={nation}>
                              {nation}
                              </option>
                          ))}
                          </select>
                      </td>
                    </tr>

                    {/* select category */}
                    <tr>
                      <td>Select a Category: </td>
                      <td>
                          <select 
                            id="category" 
                            onChange={handleInputChange}
                            defaultValue={headerInfo.category || null}  
                          >
                            {Object.keys(CATEGORYS).map((cat) => {
                              return <option value={CATEGORYS[cat]}>{cat}</option>
                            })}
                          {/* <option value="FIS">FIS Race</option>
                          <option value="WSC">World Ski Championships</option>
                          <option value="WC">World Cup</option> */}
                          </select>
                      </td>
                    </tr>

                    {/* Used FIS list input */}
                    <tr>
                      <td>Used FIS List: </td>
                      <td>
                          <input
                          type="number"
                          id="usedFISList"
                          onChange={handleInputChange}
                          defaultValue={headerInfo.usedFISList || null}
                          ></input>
                      </td>
                    </tr>

                    {/* used NRL list input */}
                    {parentElement === "USSAfourm" ? (
                      <tr>
                        <td>Used NRL List: </td>
                        <td>
                            <input
                            type="number"
                            id="usedNRLList"
                            onChange={handleInputChange}
                            defaultValue={headerInfo.usedNRLList || null}
                            ></input>
                        </td>
                      </tr>
                    ):('')}


                    {/* results type */}
                    <tr>
                      <td>Select a Type: </td>
                      <td>
                          <select
                          id="type"
                          onChange={handleInputChange}
                          defaultValue={headerInfo.type || null}
                          >
                          <option value="Startlist">Startlist </option>
                          <option value="Partial">Partial unofficial result</option>
                          <option value="Unofficial">Unofficial result</option>
                          <option value="Official">Official results</option>
                          </select>
                      </td>
                    </tr>

                    {/* select state */}
                    {parentElement === "USSAfourm" ? (
                    <tr>
                      <td>State: </td>
                      <td>
                          <select 
                            id="state" 
                            onChange={handleInputChange}
                            defaultValue={headerInfo.state || null}  
                          >
                            {STATES.map((state) => (
                              <option value={state} key={state}>{state}</option>
                            ))}
                          </select>
                      </td>
                    </tr>
                    ):('')}

                  </tbody>
                </table>}

                {/* Race Info */}
                <div className="sub-title" id="race-info" onClick={collapseRaceInfo}>
                  <div className="sub-title-div">Race Info</div>
                  {isRaceInfoVisable ? <div className='triangle-down'></div> : <div className='triangle-down rotate-90'></div>}
                </div>
                {isRaceInfoVisable && <table 
                  className='sub-table' 
                  id="race-info-table"
                >
                  <tbody>
                    {/* select a Discipline */}
                    <tr>
                      <td>Select a Discipline: </td>
                      <td>
                          <select 
                            id="discipline" 
                            onChange={handleInputChange}
                            defaultValue={headerInfo.discipline || null}
                          >
                          <option value="SP">Sprint</option>
                          <option value="DI">Distance</option>
                          <option value="TE">Team Relay</option>
                          <option value="Tsp">Team Sprint</option>
                          <option value="Mar">Marathon</option>
                          </select>
                      </td>
                    </tr>

                    {/* race format */}
                    <tr>
                      <td>Select a Format: </td>
                      <td>
                          <select
                          id="format"
                          onChange={handleInputChange}
                          defaultValue={headerInfo.format || null}
                          >
                          <option value="individualDistance">Individual Distance</option>
                          <option value="sprintQualification">Sprint Qualification</option>
                          <option value="sprintFinal">Sprint Final</option>
                          <option value="team">Team</option>
                          <option value="teamSprint">Team Sprint</option>
                          </select>
                      </td>
                    </tr>

                    {/* Event Type */}
                    {parentElement === "USSAfourm" ? (
                      <tr>
                        <td>Event Type: </td>
                        <td>
                            <select 
                              id="eventType" 
                              onChange={handleInputChange}
                              defaultValue={headerInfo.eventType || null}
                            >
                            <option value="MS">Mass Start</option>
                            <option value="IS">Interval Start</option>
                            <option value="Qual">Qualifier</option>
                            <option value="Final">Interval Start</option>
                            <option value="Pur">Pursuit</option>
                            </select>
                        </td>
                      </tr>
                    ) : ('')}

                    {/* race date input */}
                    <tr>
                      <td>Race Date</td>
                      <td>
                          <input
                          type="date"
                          id="raceDate"
                          onChange={handleInputChange}
                          defaultValue={headerInfo.raceDate || null}
                          ></input>
                      </td>
                    </tr>
                    
                    {/* select a Technique */}
                    {parentElement === "USSAfourm" ? (
                      <tr>
                        <td>Technique: </td>
                        <td>
                            <select 
                              id="technique" 
                              onChange={handleInputChange}
                              defaultValue={headerInfo.technique || null}
                            >
                            <option value="FR">Free Style</option>
                            <option value="CL">Classic</option>
                            </select>
                        </td>
                      </tr>
                    ) : ('')}

                    {/* Distance */}
                    {parentElement === "USSAfourm" ? (
                      <tr>
                        <td>Distance: </td>
                        <td>
                            <input
                            type="text"
                            id="distance"
                            onChange={handleInputChange}
                            defaultValue={headerInfo.distance || null}
                            ></input>
                        </td>
                      </tr>
                    ) : ('')}

                  </tbody>
                </table>}

                {/* Course Info */}
                <div className="sub-title" id="course-info" onClick={collapseCourseInfo}>
                  <div className="sub-title-div">Course Info</div>
                  {isCourseInfoVisable ? <div className='triangle-down'></div> : <div className='triangle-down rotate-90'></div>}
                </div>
                {isCourseInfoVisable && <table 
                  className='sub-table' 
                  id='course-info-table'
                >
                  <tbody>
                    {/* Homologation */}
                    {parentElement === "USSAfourm" ? (
                      <tr>
                        <td>Homologation: </td>
                        <td>
                          <input
                            id="homologation"
                            type="text"
                            defaultValue={headerInfo.homologation || null}
                            onChange={handleInputChange}
                            >
                          </input>
                        </td>
                      </tr>
                    ) : ('')}

                    {/* Lap Length */}
                    <tr>
                      <td>Lap Length: </td>
                      <td>
                        <input
                          id="lapLength"
                          type="text"
                          defaultValue={headerInfo.lapLength || null}
                          onChange={handleInputChange}
                          >
                        </input>
                      </td>
                    </tr>

                    {/* Lap Number */}
                    <tr>
                      <td>Lap Number: </td>
                      <td>
                        <input
                          id="lapNumber"
                          type="text"
                          defaultValue={headerInfo.lapNumber || null}
                          onChange={handleInputChange}
                          >
                        </input>
                      </td>
                    </tr>

                    {/* Coordinator */}
                    <tr>
                      <td>Coordinator: </td>
                      <td>
                        <input
                          id="coordinator"
                          type="text"
                          defaultValue={headerInfo.coordinator || null}
                          onChange={handleInputChange}
                          >
                        </input>
                      </td>
                    </tr>

                    {/* Total Climb */}
                    <tr>
                      <td>Total Climb: </td>
                      <td>
                        <input
                          id="tc"
                          type="text"
                          defaultValue={headerInfo.tc || null}
                          onChange={handleInputChange}
                          >
                        </input>
                      </td>
                    </tr>

                    {/* Height Diffrence */}
                    <tr>
                      <td>Height Diffrence: </td>
                      <td>
                        <input
                          id="hd"
                          type="text"
                          defaultValue={headerInfo.hd || null}
                          onChange={handleInputChange}
                          >
                        </input>
                      </td>
                    </tr>

                    {/* Maximum Climb */}
                    <tr>
                      <td>Maximum Climb: </td>
                      <td>
                        <input
                          id="mc"
                          type="text"
                          defaultValue={headerInfo.mc || null}
                          onChange={handleInputChange}
                          >
                        </input>
                      </td>
                    </tr>
                  </tbody>
                </table>}

                {/* File Info */}
                <div className="sub-title" id="file-info" onClick={collapseFileInfo}>
                  <div className="sub-title-div">File Info</div>
                  {isFileInfoVisable ? <div className='triangle-down'></div> : <div className='triangle-down rotate-90'></div>}
                </div>
                {isFileInfoVisable && <table 
                  className='sub-table' 
                  id='file-info-table'
                >
                  <tbody>
                    {/* select sex */}
                    <tr>
                      <td>Select a Sex: </td>
                      <td>
                          <select
                          id="sex"
                          defaultValue={headerInfo.sex || null}
                          onChange={handleInputChange}
                          >
                          <option value="M">Male</option>
                          <option value="W">Female</option>
                          <option value="A">Team</option>
                          </select>
                      </td>
                    </tr>

                    {/* codex input */}
                    <tr>
                      <td>Codex: </td>
                      <td>
                          <input
                          type="number"
                          id="codex"
                          onChange={handleInputChange}
                          defaultValue={headerInfo.codex || null}
                          ></input>
                      </td>
                    </tr>

                    {/* National Race Code (NRC) input */}
                    {parentElement === "USSAfourm" ? (
                      <tr>
                        <td>National Race Code: </td>
                        <td>
                            <input
                            type="text"
                            id="NRC"
                            onChange={handleInputChange}
                            defaultValue={headerInfo.NRC || null}
                            ></input>
                        </td>
                      </tr>
                    ):('')}

                    {/* Start time input */}
                    <tr>
                      <td>Start (Time in 24h): </td>
                      <td>
                          <input
                          type="text"
                          id="startTime"
                          onChange={handleInputChange}
                          defaultValue={headerInfo.startTime || null}
                          ></input>
                      </td>
                    </tr>
                  </tbody>
                </table>}
            </form>
          </div>
      </div>
      </>
  );
}


export default HeaderInfoFourm;