import React from 'react';
import FISFourm from './FISFourm';
import USSAFourm from './USSAFourm';
import Documentation from './Documentation'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<FISFourm/>} />
        <Route exact path="/ussa" element={<USSAFourm/>} />
        <Route exact path="/documentation" element={<Documentation/>} />
      </Routes>
    </Router>
  );
}

export default App;
