import React from "react";
import './NavBar.css'

function NavBar(props) {
    const current = props.current

    // disables for FIS, USSA and Documentation tabs
    const isFISDisabled = false;
    const isUSSADisabled = false;
    const isDocDisabled = false;

    const notDoneWarning = (event) => {
        const elementId = event.target.id;
        window.alert(`Sorry, ${elementId} is not done yet.`);
    }

    return(
        <>
        <nav className="narbar">
            <ul className="navbar-ul">
                <li className={`nav-item`}>
                    <a 
                        id="FIS XML Genarator"
                        href={isFISDisabled ? ('/') : ("/")}
                        onClick={isFISDisabled ? notDoneWarning : undefined}
                        className={`${ current === 'FIS' ? ('current') : ('') }`}
                    >FIS</a>
                </li>
                <li className={`nav-item`}>
                    <a 
                        id="USSA XML Genarator"
                        href={isUSSADisabled ? ('/') : ("/ussa")}
                        onClick={isUSSADisabled ? notDoneWarning : undefined}
                        className={`${ current === 'USSA' ? ('current') : ('') }`}
                    >USSA</a>
                </li>
                <li className={`nav-item`}>
                    <a 
                        id="Documentation"
                        href={isDocDisabled ? ('/') : ("/documentation")}
                        onClick={isDocDisabled ? notDoneWarning : undefined}
                        className={`${ current === 'Documentation' ? ('current') : ('') }`}
                    >Documentation</a>
                </li>
            </ul>
        </nav>
        </>
    )
}


export default NavBar