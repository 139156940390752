import './css/Zone4Title.css'

function Zone4Title(props){
    return(
        <>
            <div className="zone4Title" onClick={()=>props.clickFunction()}>
                <h2 className="title">{props.title}</h2>
                {props.status ? <div className='triangle-down'></div> : <div className='triangle-down rotate-90'></div>}
            </div>
        </>
    )
}

export default Zone4Title;