import JurryFourm from "./util/JurryFourm";
import Zone4Title from "./util/Zone4Title";
import HeaderInfoFourm from "./util/HeaderInfoFourm";
import DataCleanup from "./util/FISDataCleanUp";
import FileInput from "./util/FileInput";
import CSVData from "./util/CSVData";
import MakeFISXML from "./util/MakeFISXML";
import FisPointsDownload from "./FISPointsDownload";
import NavBar from './NavBar'
import Zone4Header from './util/Zone4Header'

import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import './MainFourm.css'
import packageJson from '../package.json';

function useLocalStorage(key, initialValue) {
    const savedValue = localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : initialValue;
  
    const [value, setValue] = useState(savedValue);
  
    useEffect(() => {
      localStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
  
    return [value, setValue];
  }

function FISFourm() {
    const APP_VERSION = packageJson.version
    const [file, setFile] = useState(null)
    const [csvData, setCsvData] = useState(null)
    const [jurry, setJurry] = useState(JSON.parse(localStorage.getItem("FISStoredJury")) || []);
    const [headerInfo, setHeaderInfo] = useState(JSON.parse(localStorage.getItem("FISHeaderInfo")) || {
        sex: "M",
        nation: "CAN",
        discipline: "SP",
        category: "FIS",
        type: "Startlist",
        format: "individualDistance",
        raceDate: new Date().toISOString().slice(0, 10),
        tempunit: "C",
        longunit: "m",
        speedunit: "kmh",
        windunit: "ms",
        timingBy: "Zone4Systems",
        dataProcessingBy: "Zone4Systems",
        softWareCompany: "Zone4Systems",
        state: 'AL',
        technique: 'FR',
        
    })

    // VISABILITIES
    const [fileInputVis, setFileInputVis] = useLocalStorage('fileInputVis', true);
    const [headerInfoFourmVis, setHeaderInfoFourmVis] = useLocalStorage('headerInfoFourmVis', true);
    const [dataCleanUpVis, setDataCleanUpVis] = useLocalStorage('dataCleanUpVis', true);
    const [jurryFourmVis, setJurryFourmVis] = useLocalStorage('jurryFourmVis', true);
    const [makeXMLVis, setMakeXMLVis] = useLocalStorage('makeXMLVis', true);
    const [fisPointsDownloadVis, setFisPointsDownloadVis] = useLocalStorage('fisPointsDownloadVis', false);
    const [csvDataVis, setCsvDataVis] = useLocalStorage('csvDataVis', true);

    useEffect(() => {
        if (file) {
            setHeaderInfo((prevHeaderInfo) => ({
                ...prevHeaderInfo,
                "file": file,
                "fileName": file.name,
            }));
            
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                // set headers
                const headers_without_ = results.meta.fields.filter((header) => {
                    return header.charAt(0) !== "_";
                });

                setHeaderInfo((prevHeaderInfo) => ({
                    ...prevHeaderInfo,
                    "headers": headers_without_,
                }));

                // remove rows where first name and last name are empty
                results.data = results.data.filter((row) => {
                    return row["firstname"] !== "" && row["lastname"] !== "";
                });

                // remove rows where the header sarts with _
                results.data = results.data.filter((row) => {
                    // go through each header
                    for (const header in row) {
                        // if the header starts with _ then remove the row
                        if(header.charAt(0) === "_"){
                            // delete that header and value
                            delete row[header];
                        }
                    }
                    return row;
                });


                setCsvData(results.data);
                setHeaderInfo((prevHeaderInfo) => ({
                    ...prevHeaderInfo,
                    "originalData": JSON.parse(JSON.stringify(results.data)),
                }));
                console.log("setCsvData:",results.data);
                },
            });
        }
    }, [file]);

    // CONSOLE LOGS
    useEffect(() => {
        console.log("headerInfo", headerInfo);
    }, [headerInfo]);

    useEffect(() => {
        console.log('jurry:', jurry);
    },[jurry]);

    return (
        <>
        <div className="Centerd-body">
            <div className="fixed-width-box">
                <div className="app-version"></div>
                <Zone4Header version={APP_VERSION}/>
                <NavBar current='FIS'/>
                <Zone4Title title={`Upload CSV`} clickFunction={() => setFileInputVis(!fileInputVis)} status={fileInputVis}/> 
                {fileInputVis && <FileInput setFile={setFile} />}

                <Zone4Title title="Header Info" clickFunction={() => setHeaderInfoFourmVis(!headerInfoFourmVis)} status={headerInfoFourmVis}/> 
                {headerInfoFourmVis && <HeaderInfoFourm setHeaderInfo={setHeaderInfo} headerInfo={headerInfo} parent={'FISfourm'}/>}

                <Zone4Title title="Jury" clickFunction={() => setJurryFourmVis(!jurryFourmVis)} status={jurryFourmVis}/> 
                {jurryFourmVis && <JurryFourm jurry={jurry} setJurry={setJurry} parentElement={'FISfourm'}/>}

                <Zone4Title title="Data Cleanup" clickFunction={() => setDataCleanUpVis(!dataCleanUpVis)} status={dataCleanUpVis}/> 
                {dataCleanUpVis && <DataCleanup csvData={csvData} setCsvData={setCsvData} headerInfo={headerInfo} setHeaderInfo={setHeaderInfo}/>}

                <Zone4Title title="Download XML" clickFunction={() => setMakeXMLVis(!makeXMLVis)} status={makeXMLVis}/>
                {makeXMLVis && <MakeFISXML headerInfo={headerInfo} csvData={csvData} jurry={jurry} parentElement={'FISfourm'}/>}

                <Zone4Title title="Download FIS Points CSV" clickFunction={() => setFisPointsDownloadVis(!fisPointsDownloadVis)} status={fisPointsDownloadVis}/>
                {fisPointsDownloadVis && <FisPointsDownload />}

                <Zone4Title title="File Preview" clickFunction={() => setCsvDataVis(!csvDataVis)} status={csvDataVis}/> 
                {csvDataVis && <CSVData csvData={csvData} headerInfo={headerInfo}/>}
            </div>
            <div className="rendering-test">rendering test</div>
        </div>
        </>
    )
};

export default FISFourm;