import React from 'react';
import USSADataCleanup from './USSADataCleanup'
import MakeUSSAXML from './MakeUSSAXML'

function USSASubFourm(props) {
    return(
        <>
            <USSADataCleanup csvData={props.csvData} setCsvData={props.setCsvData} headerInfo={props.headerInfo} setHeaderInfo={props.setHeaderInfo}/>
            <MakeUSSAXML headerInfo={props.headerInfo} csvData={props.csvData} jurry={props.jurry} parentElement={'USSAfourm'} />
        </>
    )
}

export default USSASubFourm