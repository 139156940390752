import React from 'react';
import './css/CSVData.css';

function CSVData(props){
    const data  = props.csvData;
    const headers = props.headerInfo.headers;
    const headerInfo = props.headerInfo;

    return(
        <>
        <div id="csvData">
            <div id='table-wraper'>
            {data && data.length !== 0 ? (
                <table>
                    <thead>
                    <tr id='headers'>
                        {headers.map((header, index) => (
                        <th key={`header-${header}-${index}`}>{header}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((row, idx) => (
                        <tr key={`row-${idx}`}>
                        {headers.map((header, index) => (
                            <td 
                                key={`cell-${idx}-${index}`}
                                className={header === 'firstname' || header === 'lastname' ? 'name' : ''}
                            >
                                {
                                    (row[header] && String(row[header]) !== 'NaN')  ? 
                                        (
                                            (header === 'Race Points' && String(row[header]) !== 'NaN' && !isNaN(parseFloat(row[header])) && !isNaN(parseFloat(headerInfo['penalty']))) ? 
                                                (parseFloat(row[header])) :
                                                (String(row[header]))
                                        ) :
                                        (`\u00a0`)
                                }
                            </td>
                        ))}
                        </tr>
                    ))}
                    </tbody> 
                </table>
            ) : (
                <h4>No Data Yet. Import a file.</h4>
            )}
            </div>
        </div>
        <div className='rendering-test'>rendering_test_csv_data</div>
        </>
    )
}

export default CSVData;